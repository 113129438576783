/* eslint-disable react/no-danger */
import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { connect } from 'redux-zero/react';

import Button from '../../../components/common/button';
import Loader from '../../../components/common/loader';
import Intl, { translate } from '../../../components/common/intl';

import { route } from '../../../helpers/router';
import { isUndefined } from '../../../helpers/types';
import { setTempSrs } from '../../findme/srs/actions';
import { setSkipHomePage } from '../../../components/app/actions';
import { setShowUserInfoModal } from '../../../components/userInfoModal/actions';

import UserInfoModal from '../../../components/userInfoModal';

import style from '../style.scss';


const LiveEvent = (props) => {
  const {
    geofence,
    geolocationEnabled,
    onboardingDisplayed,
    isTicketMode,
    srs,
    skipHomePage,
    termsAccepted,
    isCheckingTicketMode,
    userInfo,
    showUserInfoModal,
    setTempSrsAction,
    setSkipHomePageAction,
    setShowUserInfoModalAction,
  } = props;
  const start = () => {
    if (isUndefined(onboardingDisplayed) || !onboardingDisplayed) {
      route('/onboarding');
    } else if (geofence.enabled && !geolocationEnabled) {
      route('/location');
    } else if (!termsAccepted) {
      route('/terms');
    } else if (isTicketMode) {
      if (['box', 'suite'].includes(srs.type) && srs.row === '1' && srs.seat === '1') {
        setTempSrsAction({}, { row: true, seat: true });
      }
      route('/findme/confirm');
    } else {
      route('/findme');
    }
  };

  const checkUserInfoHandler = () => {
    if (!userInfo.firstName || !userInfo.lastName || !userInfo.email) {
      setShowUserInfoModalAction(true);
      return;
    }
    start();
  };

  useEffect(() => {
    // this will skip landing page to confirm page if SRS are valid
    if (skipHomePage && !isCheckingTicketMode && isTicketMode) {
      setSkipHomePageAction(false);
      start();
    }
  }, [skipHomePage, isCheckingTicketMode, isTicketMode]);

  if (skipHomePage && (isCheckingTicketMode || isTicketMode)) {
    return <Loader />;
  }

  return (
    <div className={style.container}>
      <h1
        className="textContainer inverted"
        dangerouslySetInnerHTML={{
          __html: translate(
            'I18N_LIVE_EVENT_TITLE',
            'Control our Fan Cam to take private group photos',
          ),
        }}
      />
      <h3
        className="textContainer inverted"
        dangerouslySetInnerHTML={{
          __html: translate(
            'I18N_LIVE_EVENT_SUBTITLE',
            'Capture your moment in the crowd',
          ),
        }}
      />
      <Button
        disabled={isCheckingTicketMode}
        primary
        onClick={process.env.USER_INFO_MODAL === true ? checkUserInfoHandler : start}
      >
        <Intl textKey="I18N_START_BUTTON">Get Started</Intl>
      </Button>
      {process.env.USER_INFO_MODAL === true && showUserInfoModal && (
        <UserInfoModal onSuccess={start} onClose={() => setShowUserInfoModalAction(false)} />
      )}
    </div>
  );
};

export default connect(({
  event: { geofence },
  geolocationEnabled,
  onboardingDisplayed,
  termsAccepted,
  isTicketMode,
  srs,
  skipHomePage,
  isCheckingTicketMode,
  userInfo = {},
  showUserInfoModal,
}) => ({
  geofence,
  geolocationEnabled,
  onboardingDisplayed,
  termsAccepted,
  isTicketMode,
  srs,
  skipHomePage,
  isCheckingTicketMode,
  userInfo,
  showUserInfoModal,
}), () => ({
  setTempSrsAction: setTempSrs,
  setSkipHomePageAction: setSkipHomePage,
  setShowUserInfoModalAction: setShowUserInfoModal,
}))(LiveEvent);
